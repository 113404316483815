import './navbar.styles.css';

const NavBar = () =>{

    return (
        <div className='navbar'>
            <ul>
                <li><a class="active">Rabindra</a></li>
                <br></br>
                <br></br>
               
                {/* <li><a></a></li> */}
            </ul>           
        </div>
    );
};

export {NavBar};